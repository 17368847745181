import { makeAutoObservable } from 'mobx';
import { LeaderboardSymbolMobxDto } from './leaderboard-symbol-mobx-dto';
import { LeaderboardDto } from '../../../../defs/api';
import { LeaderboardPositionDetailMobxDto } from './leaderboard-position-detail-mobx-dto';
import { LeaderboardMaxPositionsMobxDto } from './leaderboard-max-positions-mobx-dto';

export class LeaderboardMobxDto implements LeaderboardDto {
    mostPurchasedSymbols: LeaderboardSymbolMobxDto[] = [];

    trendingSymbols: LeaderboardSymbolMobxDto[] = [];

    mostSoldSymbols: LeaderboardSymbolMobxDto[] = [];

    mostProfitableSymbols: LeaderboardSymbolMobxDto[] = [];

    portfoliosValueM: number = 0;

    investedValueM: number = 0;

    transactionAmountK: number = 0;

    dividendYieldPosition?: LeaderboardPositionDetailMobxDto | null;

    investmentValuePosition?: LeaderboardPositionDetailMobxDto | null;

    profitabilityPosition?: LeaderboardPositionDetailMobxDto | null;

    transactionAmountPosition?: LeaderboardPositionDetailMobxDto | null;

    maxPositions: LeaderboardMaxPositionsMobxDto;

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: LeaderboardDto): LeaderboardMobxDto {
        const mobxDto = new LeaderboardMobxDto();
        return Object.assign(mobxDto, dto, {
            mostPurchasedSymbols: LeaderboardSymbolMobxDto.createFromArray(dto.mostPurchasedSymbols),
            trendingSymbols: LeaderboardSymbolMobxDto.createFromArray(dto.trendingSymbols),
            mostSoldSymbols: LeaderboardSymbolMobxDto.createFromArray(dto.mostSoldSymbols),
        });
    }

    static createFromArray(dtos: LeaderboardDto[]): LeaderboardMobxDto[] {
        return dtos.map((dto) => LeaderboardMobxDto.create(dto));
    }
}
