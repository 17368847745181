import { makeAutoObservable, runInAction } from 'mobx';
import type { RootStore } from './root-store';
import { SymbolPriceAlertDialogStore } from './symbol-price-alert-dialog-store';
import { SymbolDetailStore } from './symbol-detail/symbol-detail-store';
import { SymbolDialogStore } from './symbol-dialog-store';
import { OrderEnum, SymbolControllerGetFromWatchlistRequest, SymbolControllerGetListRequest } from '../../defs/api';
import { SymbolMobxDto } from '../mobx/dtos/symbol/symbol-mobx-dto';
import { WatchlistSymbolMobxDto } from '../mobx/dtos/watchlist/watchlist-symbol-mobx-dto';
import { SymbolGrowthRateStore } from './symbol-growth-rate-store';
import { RecalculateSymbolStore } from './recalculate-symbol-store';

export type SymbolsStoreHydration = {
    symbols: SymbolMobxDto[];
};

export type WatchlistSortModel = {
    name?: OrderEnum;
    exchange?: OrderEnum;
    marketValue?: OrderEnum;
};

const DEFAULT_SORT_MODEL: WatchlistSortModel = {
    name: OrderEnum.Desc,
};

export class SymbolsStore {
    rootStore: RootStore;

    recalculateSymbolStore: RecalculateSymbolStore;

    symbolDetailStore: SymbolDetailStore;

    symbolDialogStore: SymbolDialogStore;

    symbols: SymbolMobxDto[] = [];

    loading = false;

    private delayTimer: NodeJS.Timeout;

    filterPhrase = '';

    totalWatchlistSymbolCount = 0;

    watchlistSymbolPage = 1;

    limit = Number(process.env.NEXT_PUBLIC_PAGE_LIMIT);

    watchlistSortModel: WatchlistSortModel = {};

    symbolPriceAlertDialogStore: SymbolPriceAlertDialogStore;

    ignoreSymbolsFromWatchlistId: string | null = null;

    symbolGrowthRateStore: SymbolGrowthRateStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.watchlistSortModel = DEFAULT_SORT_MODEL;
        this.symbolPriceAlertDialogStore = new SymbolPriceAlertDialogStore(this);
        this.recalculateSymbolStore = new RecalculateSymbolStore(this);
        this.symbolDetailStore = new SymbolDetailStore(rootStore);
        // this.symbolSplitStore = new SymbolSplitStore(rootStore);
        this.symbolDialogStore = new SymbolDialogStore(this);
        this.symbolGrowthRateStore = new SymbolGrowthRateStore(rootStore);
        makeAutoObservable(this, {
            rootStore: false,
            symbolDialogStore: false,
        });
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    async triggerSearch(phrase: string): Promise<void> {
        clearTimeout(this.delayTimer);
        this.delayTimer = setTimeout(async () => {
            this.filterPhrase = phrase;
            await this.fetchSymbols();
        }, 500);
    }

    clearSearch(): void {
        this.filterPhrase = '';
        this.symbols = [];
    }

    setIgnoreSymbolsFromWatchlistId(watchlistId: string | null): void {
        this.ignoreSymbolsFromWatchlistId = watchlistId;
    }

    async fetchSymbols(): Promise<void> {
        try {
            this.startLoading();
            const query: SymbolControllerGetListRequest = {
                phrase: this.filterPhrase,
                limit: 100,
            };

            if (this.ignoreSymbolsFromWatchlistId) {
                query.watchlistId = this.ignoreSymbolsFromWatchlistId;
            }

            const fetchedSymbols = await this.rootStore.apiClient.symbolController.symbolControllerGetList(query);
            if (fetchedSymbols) {
                runInAction(() => {
                    this.symbols = SymbolMobxDto.createFromArray(fetchedSymbols);
                });
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }
    }

    async fetchWatchlistSymbols(watchlistId: string): Promise<void> {
        try {
            this.startLoading();

            const parameters: SymbolControllerGetFromWatchlistRequest = {
                watchlistId,
                limit: this.limit,
                offset: (this.watchlistSymbolPage - 1) * this.limit,
                phrase: '',
            };
            if (this.watchlistSortModel) {
                parameters.sort = this.watchlistSortModel;
            }

            const fetchedSymbols = await this.rootStore.apiClient.symbolController.symbolControllerGetFromWatchlist(
                parameters,
            );
            if (fetchedSymbols) {
                runInAction(() => {
                    this.rootStore.watchlistStore.setSymbols(
                        WatchlistSymbolMobxDto.createFromArray(fetchedSymbols.items),
                    );
                    this.totalWatchlistSymbolCount = fetchedSymbols?.total || 0;
                });
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }
    }

    async setWatchlistSymbolPage(page: number): Promise<void> {
        this.watchlistSymbolPage = page;
        this.rootStore.watchlistStore.fetchWatchlists();
    }

    async setPrevWatchlistSymbolPage(): Promise<void> {
        this.watchlistSymbolPage = this.watchlistSymbolPage - 1 > 0 ? this.watchlistSymbolPage - 1 : 1;
        this.rootStore.watchlistStore.fetchWatchlists();
    }

    get watchlistSymbolPagesCount(): number {
        return Math.ceil(this.totalWatchlistSymbolCount / this.limit);
    }

    get hasSymbols() {
        return this.symbols.length > 0;
    }

    setWatchlistSortModel(sortModel: { field: keyof WatchlistSortModel; method: OrderEnum | null }): void {
        this.watchlistSortModel = {};
        if (sortModel.method) {
            this.watchlistSortModel[sortModel.field] = sortModel.method;
        } else {
            this.watchlistSortModel = DEFAULT_SORT_MODEL;
        }

        this.setWatchlistSymbolPage(1);
    }

    getFieldSortMethod(field: keyof WatchlistSortModel): OrderEnum | undefined | '' {
        if (this.watchlistSortModel && this.watchlistSortModel[field]) {
            return this.watchlistSortModel[field];
        }
        return undefined;
    }
}
