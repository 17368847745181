/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LeaderboardMaxPositionsDto
 */
export interface LeaderboardMaxPositionsDto {
    /**
     * 
     * @type {number}
     * @memberof LeaderboardMaxPositionsDto
     */
    profitability?: number;
    /**
     * 
     * @type {number}
     * @memberof LeaderboardMaxPositionsDto
     */
    investmentValue?: number;
    /**
     * 
     * @type {number}
     * @memberof LeaderboardMaxPositionsDto
     */
    transactionAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof LeaderboardMaxPositionsDto
     */
    dividendYield?: number;
}

export function LeaderboardMaxPositionsDtoFromJSON(json: any): LeaderboardMaxPositionsDto {
    return LeaderboardMaxPositionsDtoFromJSONTyped(json, false);
}

export function LeaderboardMaxPositionsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaderboardMaxPositionsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'profitability': !exists(json, 'profitability') ? undefined : json['profitability'],
        'investmentValue': !exists(json, 'investmentValue') ? undefined : json['investmentValue'],
        'transactionAmount': !exists(json, 'transactionAmount') ? undefined : json['transactionAmount'],
        'dividendYield': !exists(json, 'dividendYield') ? undefined : json['dividendYield'],
    };
}

export function LeaderboardMaxPositionsDtoToJSON(value?: LeaderboardMaxPositionsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'profitability': value.profitability,
        'investmentValue': value.investmentValue,
        'transactionAmount': value.transactionAmount,
        'dividendYield': value.dividendYield,
    };
}

