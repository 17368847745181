/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AccessTokenDto,
    AccessTokenDtoFromJSON,
    AccessTokenDtoToJSON,
    EmailVerificationDto,
    EmailVerificationDtoFromJSON,
    EmailVerificationDtoToJSON,
    LoginUserDto,
    LoginUserDtoFromJSON,
    LoginUserDtoToJSON,
    ResetPasswordDto,
    ResetPasswordDtoFromJSON,
    ResetPasswordDtoToJSON,
    ResetPasswordTokenPayloadDto,
    ResetPasswordTokenPayloadDtoFromJSON,
    ResetPasswordTokenPayloadDtoToJSON,
    SignUpResponseDto,
    SignUpResponseDtoFromJSON,
    SignUpResponseDtoToJSON,
    SignUpUserDto,
    SignUpUserDtoFromJSON,
    SignUpUserDtoToJSON,
    UserProfileDto,
    UserProfileDtoFromJSON,
    UserProfileDtoToJSON,
} from '../models';

export interface AuthControllerConfirmEmailRequest {
    accessTokenDto: AccessTokenDto;
}

export interface AuthControllerImpersonationRequest {
    email: string;
}

export interface AuthControllerLoginRequest {
    loginUserDto: LoginUserDto;
}

export interface AuthControllerResetPasswordRequest {
    resetPasswordDto: ResetPasswordDto;
}

export interface AuthControllerSendResetPasswordEmailRequest {
    resetPasswordTokenPayloadDto: ResetPasswordTokenPayloadDto;
}

export interface AuthControllerSendVerificationLinkRequest {
    emailVerificationDto: EmailVerificationDto;
}

export interface AuthControllerSignUpRequest {
    signUpUserDto: SignUpUserDto;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     */
    async authControllerAppleAuthRedirectRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/apple/redirect`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async authControllerAppleAuthRedirect(initOverrides?: RequestInit): Promise<void> {
        await this.authControllerAppleAuthRedirectRaw(initOverrides);
    }

    /**
     */
    async authControllerAppleLoginRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/apple`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async authControllerAppleLogin(initOverrides?: RequestInit): Promise<void> {
        await this.authControllerAppleLoginRaw(initOverrides);
    }

    /**
     * Confirm email address
     */
    async authControllerConfirmEmailRaw(requestParameters: AuthControllerConfirmEmailRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.accessTokenDto === null || requestParameters.accessTokenDto === undefined) {
            throw new runtime.RequiredError('accessTokenDto','Required parameter requestParameters.accessTokenDto was null or undefined when calling authControllerConfirmEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/auth/confirm-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AccessTokenDtoToJSON(requestParameters.accessTokenDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Confirm email address
     */
    async authControllerConfirmEmail(requestParameters: AuthControllerConfirmEmailRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.authControllerConfirmEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get user profile
     */
    async authControllerGetProfileRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserProfileDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/auth/profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserProfileDtoFromJSON(jsonValue));
    }

    /**
     * Get user profile
     */
    async authControllerGetProfile(initOverrides?: RequestInit): Promise<UserProfileDto> {
        const response = await this.authControllerGetProfileRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async authControllerGoogleAuthRedirectRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/google/redirect`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async authControllerGoogleAuthRedirect(initOverrides?: RequestInit): Promise<void> {
        await this.authControllerGoogleAuthRedirectRaw(initOverrides);
    }

    /**
     */
    async authControllerGoogleAuthTokenRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<AccessTokenDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/google/token`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccessTokenDtoFromJSON(jsonValue));
    }

    /**
     */
    async authControllerGoogleAuthToken(initOverrides?: RequestInit): Promise<AccessTokenDto> {
        const response = await this.authControllerGoogleAuthTokenRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async authControllerGoogleLoginRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/google`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async authControllerGoogleLogin(initOverrides?: RequestInit): Promise<void> {
        await this.authControllerGoogleLoginRaw(initOverrides);
    }

    /**
     * Get user JWT token
     */
    async authControllerImpersonationRaw(requestParameters: AuthControllerImpersonationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AccessTokenDto>> {
        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling authControllerImpersonation.');
        }

        const queryParameters: any = {};

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/auth/impersonation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccessTokenDtoFromJSON(jsonValue));
    }

    /**
     * Get user JWT token
     */
    async authControllerImpersonation(requestParameters: AuthControllerImpersonationRequest, initOverrides?: RequestInit): Promise<AccessTokenDto> {
        const response = await this.authControllerImpersonationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Login
     */
    async authControllerLoginRaw(requestParameters: AuthControllerLoginRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AccessTokenDto>> {
        if (requestParameters.loginUserDto === null || requestParameters.loginUserDto === undefined) {
            throw new runtime.RequiredError('loginUserDto','Required parameter requestParameters.loginUserDto was null or undefined when calling authControllerLogin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/auth/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginUserDtoToJSON(requestParameters.loginUserDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccessTokenDtoFromJSON(jsonValue));
    }

    /**
     * Login
     */
    async authControllerLogin(requestParameters: AuthControllerLoginRequest, initOverrides?: RequestInit): Promise<AccessTokenDto> {
        const response = await this.authControllerLoginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reset password
     */
    async authControllerResetPasswordRaw(requestParameters: AuthControllerResetPasswordRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.resetPasswordDto === null || requestParameters.resetPasswordDto === undefined) {
            throw new runtime.RequiredError('resetPasswordDto','Required parameter requestParameters.resetPasswordDto was null or undefined when calling authControllerResetPassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/auth/reset-password`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordDtoToJSON(requestParameters.resetPasswordDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Reset password
     */
    async authControllerResetPassword(requestParameters: AuthControllerResetPasswordRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.authControllerResetPasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Send reset password email
     */
    async authControllerSendResetPasswordEmailRaw(requestParameters: AuthControllerSendResetPasswordEmailRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.resetPasswordTokenPayloadDto === null || requestParameters.resetPasswordTokenPayloadDto === undefined) {
            throw new runtime.RequiredError('resetPasswordTokenPayloadDto','Required parameter requestParameters.resetPasswordTokenPayloadDto was null or undefined when calling authControllerSendResetPasswordEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/auth/send-reset-password-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordTokenPayloadDtoToJSON(requestParameters.resetPasswordTokenPayloadDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Send reset password email
     */
    async authControllerSendResetPasswordEmail(requestParameters: AuthControllerSendResetPasswordEmailRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.authControllerSendResetPasswordEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Send verification email
     */
    async authControllerSendVerificationLinkRaw(requestParameters: AuthControllerSendVerificationLinkRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.emailVerificationDto === null || requestParameters.emailVerificationDto === undefined) {
            throw new runtime.RequiredError('emailVerificationDto','Required parameter requestParameters.emailVerificationDto was null or undefined when calling authControllerSendVerificationLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/auth/send-verification-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmailVerificationDtoToJSON(requestParameters.emailVerificationDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Send verification email
     */
    async authControllerSendVerificationLink(requestParameters: AuthControllerSendVerificationLinkRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.authControllerSendVerificationLinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sign up
     */
    async authControllerSignUpRaw(requestParameters: AuthControllerSignUpRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SignUpResponseDto>> {
        if (requestParameters.signUpUserDto === null || requestParameters.signUpUserDto === undefined) {
            throw new runtime.RequiredError('signUpUserDto','Required parameter requestParameters.signUpUserDto was null or undefined when calling authControllerSignUp.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/auth/sign-up`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignUpUserDtoToJSON(requestParameters.signUpUserDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignUpResponseDtoFromJSON(jsonValue));
    }

    /**
     * Sign up
     */
    async authControllerSignUp(requestParameters: AuthControllerSignUpRequest, initOverrides?: RequestInit): Promise<SignUpResponseDto> {
        const response = await this.authControllerSignUpRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authControllerTwitterAuthRedirectRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/twitter/redirect`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async authControllerTwitterAuthRedirect(initOverrides?: RequestInit): Promise<void> {
        await this.authControllerTwitterAuthRedirectRaw(initOverrides);
    }

    /**
     */
    async authControllerTwitterLoginRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/twitter`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async authControllerTwitterLogin(initOverrides?: RequestInit): Promise<void> {
        await this.authControllerTwitterLoginRaw(initOverrides);
    }

}
