import { makeAutoObservable } from 'mobx';
import { ColorEnum, IconNameEnum } from '@app/common';
import { PortfolioDto, PortfolioTypeEnum, ShareTypeEnum } from '../../../../defs/api';
import { CurrencyMobxDto } from '../currency/currency-mobx-dto';

export class PortfolioMobxDto implements PortfolioDto {
    public id: string = '';

    public name: string = '';

    public iconName: IconNameEnum = IconNameEnum.WalletFilled;

    public colorName: ColorEnum = ColorEnum.Blue;

    public currency: CurrencyMobxDto = new CurrencyMobxDto();

    public userId: string = '';

    public selected = false;

    public portfolioValue = 0;

    public invested = 0;

    public realizedPortfolioValue = 0;

    public realizedInvested = 0;

    public realizedProfit = 0;

    public realizedProfitPercentage = 0;

    public unrealizedProfit = 0;

    public unrealizedProfitPercentage = 0;

    public numberOfTrades = 0;

    public numberOfAssets = 0;

    public isRecalculating = false;

    public portfolioHistoryCurrencyCode = '';

    public processed = false;

    public currencyImpactUnrealizedValue: number = 0;

    public currencyImpactRealizedValue: number = 0;

    public todayUnrealizedProfit: number = 0;

    public todayUnrealizedProfitPercentage: number = 0;

    public dividends: number = 0;

    public dividendsNextYearPrediction: number = 0;

    public dividendsYield: number = 0;

    public dividendsYieldOnCost: number = 0;

    public type: PortfolioTypeEnum = PortfolioTypeEnum.User;

    public lastUpdated: Date = new Date();

    public recalculationEndDate: Date | null = null;

    public shareType: ShareTypeEnum = ShareTypeEnum.Disabled;

    public order: number = 0;

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: PortfolioDto): PortfolioMobxDto {
        const mobxDto = new PortfolioMobxDto();
        return Object.assign(mobxDto, dto, { currency: CurrencyMobxDto.create(dto.currency) });
    }

    static createFromArray(dtos: PortfolioDto[]): PortfolioMobxDto[] {
        const createdDtos: PortfolioMobxDto[] = [];
        let summaryPortfolio: PortfolioMobxDto | null = null;
        dtos.forEach((dto) => {
            if (dto.type === PortfolioTypeEnum.Summary) {
                summaryPortfolio = PortfolioMobxDto.create(dto);
            } else {
                createdDtos.push(PortfolioMobxDto.create(dto));
            }
        });

        if (summaryPortfolio) {
            createdDtos.push(summaryPortfolio);
        }

        return createdDtos;
    }

    setSelected(selected: boolean) {
        this.selected = selected;
    }

    setIsRecalculating(isRecalculating: boolean) {
        this.isRecalculating = isRecalculating;
    }
}
