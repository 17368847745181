/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LeaderboardMaxPositionsDto,
    LeaderboardMaxPositionsDtoFromJSON,
    LeaderboardMaxPositionsDtoFromJSONTyped,
    LeaderboardMaxPositionsDtoToJSON,
} from './LeaderboardMaxPositionsDto';
import {
    LeaderboardPositionDetailDto,
    LeaderboardPositionDetailDtoFromJSON,
    LeaderboardPositionDetailDtoFromJSONTyped,
    LeaderboardPositionDetailDtoToJSON,
} from './LeaderboardPositionDetailDto';
import {
    LeaderboardSymbolDto,
    LeaderboardSymbolDtoFromJSON,
    LeaderboardSymbolDtoFromJSONTyped,
    LeaderboardSymbolDtoToJSON,
} from './LeaderboardSymbolDto';

/**
 * 
 * @export
 * @interface LeaderboardDto
 */
export interface LeaderboardDto {
    /**
     * 
     * @type {Array<LeaderboardSymbolDto>}
     * @memberof LeaderboardDto
     */
    mostPurchasedSymbols: Array<LeaderboardSymbolDto>;
    /**
     * 
     * @type {Array<LeaderboardSymbolDto>}
     * @memberof LeaderboardDto
     */
    trendingSymbols: Array<LeaderboardSymbolDto>;
    /**
     * 
     * @type {Array<LeaderboardSymbolDto>}
     * @memberof LeaderboardDto
     */
    mostSoldSymbols: Array<LeaderboardSymbolDto>;
    /**
     * 
     * @type {Array<LeaderboardSymbolDto>}
     * @memberof LeaderboardDto
     */
    mostProfitableSymbols: Array<LeaderboardSymbolDto>;
    /**
     * 
     * @type {LeaderboardMaxPositionsDto}
     * @memberof LeaderboardDto
     */
    maxPositions?: LeaderboardMaxPositionsDto | null;
    /**
     * 
     * @type {number}
     * @memberof LeaderboardDto
     */
    transactionAmountK: number;
    /**
     * 
     * @type {number}
     * @memberof LeaderboardDto
     */
    investedValueM: number;
    /**
     * 
     * @type {number}
     * @memberof LeaderboardDto
     */
    portfoliosValueM: number;
    /**
     * 
     * @type {LeaderboardPositionDetailDto}
     * @memberof LeaderboardDto
     */
    profitabilityPosition?: LeaderboardPositionDetailDto | null;
    /**
     * 
     * @type {LeaderboardPositionDetailDto}
     * @memberof LeaderboardDto
     */
    investmentValuePosition?: LeaderboardPositionDetailDto | null;
    /**
     * 
     * @type {LeaderboardPositionDetailDto}
     * @memberof LeaderboardDto
     */
    transactionAmountPosition?: LeaderboardPositionDetailDto | null;
    /**
     * 
     * @type {LeaderboardPositionDetailDto}
     * @memberof LeaderboardDto
     */
    dividendYieldPosition?: LeaderboardPositionDetailDto | null;
}

export function LeaderboardDtoFromJSON(json: any): LeaderboardDto {
    return LeaderboardDtoFromJSONTyped(json, false);
}

export function LeaderboardDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaderboardDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mostPurchasedSymbols': ((json['mostPurchasedSymbols'] as Array<any>).map(LeaderboardSymbolDtoFromJSON)),
        'trendingSymbols': ((json['trendingSymbols'] as Array<any>).map(LeaderboardSymbolDtoFromJSON)),
        'mostSoldSymbols': ((json['mostSoldSymbols'] as Array<any>).map(LeaderboardSymbolDtoFromJSON)),
        'mostProfitableSymbols': ((json['mostProfitableSymbols'] as Array<any>).map(LeaderboardSymbolDtoFromJSON)),
        'maxPositions': !exists(json, 'maxPositions') ? undefined : LeaderboardMaxPositionsDtoFromJSON(json['maxPositions']),
        'transactionAmountK': json['transactionAmountK'],
        'investedValueM': json['investedValueM'],
        'portfoliosValueM': json['portfoliosValueM'],
        'profitabilityPosition': !exists(json, 'profitabilityPosition') ? undefined : LeaderboardPositionDetailDtoFromJSON(json['profitabilityPosition']),
        'investmentValuePosition': !exists(json, 'investmentValuePosition') ? undefined : LeaderboardPositionDetailDtoFromJSON(json['investmentValuePosition']),
        'transactionAmountPosition': !exists(json, 'transactionAmountPosition') ? undefined : LeaderboardPositionDetailDtoFromJSON(json['transactionAmountPosition']),
        'dividendYieldPosition': !exists(json, 'dividendYieldPosition') ? undefined : LeaderboardPositionDetailDtoFromJSON(json['dividendYieldPosition']),
    };
}

export function LeaderboardDtoToJSON(value?: LeaderboardDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mostPurchasedSymbols': ((value.mostPurchasedSymbols as Array<any>).map(LeaderboardSymbolDtoToJSON)),
        'trendingSymbols': ((value.trendingSymbols as Array<any>).map(LeaderboardSymbolDtoToJSON)),
        'mostSoldSymbols': ((value.mostSoldSymbols as Array<any>).map(LeaderboardSymbolDtoToJSON)),
        'mostProfitableSymbols': ((value.mostProfitableSymbols as Array<any>).map(LeaderboardSymbolDtoToJSON)),
        'maxPositions': LeaderboardMaxPositionsDtoToJSON(value.maxPositions),
        'transactionAmountK': value.transactionAmountK,
        'investedValueM': value.investedValueM,
        'portfoliosValueM': value.portfoliosValueM,
        'profitabilityPosition': LeaderboardPositionDetailDtoToJSON(value.profitabilityPosition),
        'investmentValuePosition': LeaderboardPositionDetailDtoToJSON(value.investmentValuePosition),
        'transactionAmountPosition': LeaderboardPositionDetailDtoToJSON(value.transactionAmountPosition),
        'dividendYieldPosition': LeaderboardPositionDetailDtoToJSON(value.dividendYieldPosition),
    };
}

