/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    FirstLeaderboardDateDto,
    FirstLeaderboardDateDtoFromJSON,
    FirstLeaderboardDateDtoToJSON,
    LeaderboardDto,
    LeaderboardDtoFromJSON,
    LeaderboardDtoToJSON,
} from '../models';

export interface LeaderboardControllerGetLeaderboardRequest {
    month: number;
    year: number;
}

/**
 * 
 */
export class LeaderboardApi extends runtime.BaseAPI {

    /**
     * Get first leaderboard month and year
     */
    async leaderboardControllerGetFirstLeaderboardDateRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<FirstLeaderboardDateDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/leaderboard/first-leaderboard-date`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FirstLeaderboardDateDtoFromJSON(jsonValue));
    }

    /**
     * Get first leaderboard month and year
     */
    async leaderboardControllerGetFirstLeaderboardDate(initOverrides?: RequestInit): Promise<FirstLeaderboardDateDto> {
        const response = await this.leaderboardControllerGetFirstLeaderboardDateRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get leaderboard
     */
    async leaderboardControllerGetLeaderboardRaw(requestParameters: LeaderboardControllerGetLeaderboardRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LeaderboardDto>> {
        if (requestParameters.month === null || requestParameters.month === undefined) {
            throw new runtime.RequiredError('month','Required parameter requestParameters.month was null or undefined when calling leaderboardControllerGetLeaderboard.');
        }

        if (requestParameters.year === null || requestParameters.year === undefined) {
            throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling leaderboardControllerGetLeaderboard.');
        }

        const queryParameters: any = {};

        if (requestParameters.month !== undefined) {
            queryParameters['month'] = requestParameters.month;
        }

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/leaderboard`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LeaderboardDtoFromJSON(jsonValue));
    }

    /**
     * Get leaderboard
     */
    async leaderboardControllerGetLeaderboard(requestParameters: LeaderboardControllerGetLeaderboardRequest, initOverrides?: RequestInit): Promise<LeaderboardDto> {
        const response = await this.leaderboardControllerGetLeaderboardRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
