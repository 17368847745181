import { makeAutoObservable, runInAction } from 'mobx';
import { ImportTypeEnum } from '@app/common';
import type { TradeStore } from './trade-store';
import type { RootStore } from './root-store';
import i18n from '../../translation/i18n';

interface ImportResultWarning {
    message: string;
    link?: string;
}

export enum fileTypes {
    CSV = 'CSV',
    XLSX = 'XLSX',
}
export class TradeImportDialogStore {
    rootStore: RootStore;

    tradeStore: TradeStore;

    opened = false;

    file: File | null = null;

    importType: ImportTypeEnum = ImportTypeEnum.Degiro;

    loading = false;

    importResultMessage: string | null = null;

    importResultWarnings: ImportResultWarning[] = [];

    recalculateAfterImport: boolean = false;

    importedRowsCount: number = 0;

    constructor(tradeStore: TradeStore) {
        this.rootStore = tradeStore.rootStore;
        this.tradeStore = tradeStore;

        makeAutoObservable(this, {
            rootStore: false,
            tradeStore: false,
        });
    }

    openImportDialog(): void {
        if (this.rootStore.authStore.userHasFreeTariff) {
            this.rootStore.premiumDialogStore.open();
        } else {
            this.opened = true;
        }
        this.rootStore.alertStore.setModalPlacement();
    }

    closeImportDialog(): void {
        this.opened = false;
        this.rootStore.alertStore.setDefaultPlacement();
        this.resetUploadedFile();
    }

    resetUploadedFile(): void {
        this.file = null;
    }

    setFile(file: File) {
        this.file = file;
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    setImportType(importType: ImportTypeEnum): void {
        this.importType = importType;
    }

    setRecalculateAfterImport(recalculateAfterImport: boolean): void {
        this.recalculateAfterImport = recalculateAfterImport;
    }

    async importFile() {
        this.importResultMessage = null;
        this.importResultWarnings = [];

        try {
            const formData = new FormData();
            if (!this.file) {
                throw new Error('No uploaded file');
            }
            formData.append('trade', this.file);
            this.startLoading();

            const response = await this.rootStore.apiClient.tradeImportController.tradeImportControllerImport(
                { importType: this.importType, processTrades: this.recalculateAfterImport },
                { body: formData },
            );

            if (!response) {
                throw new Error('No response from trade import');
            }

            if (response.invalidFormat) {
                this.importResultWarnings.push({ message: i18n.t(`pages.trades.invalidFormat`) });
                return;
            }

            if (response.importedRowsCount <= 0) {
                if (response.existingTradesCount > 0) {
                    this.importResultWarnings.push({ message: i18n.t(`pages.trades.allRowsExisted`) });
                    return;
                }

                this.importResultWarnings.push({ message: i18n.t(`pages.trades.noValidRows`) });
            }

            let importedResultMessage = i18n.t(`pages.trades.imported.successfully`, {
                importedRowsCount: response.importedRowsCount,
            });
            if (response.existingTradesCount) {
                importedResultMessage += i18n.t(`pages.trades.imported.existed`, {
                    existingTradesCount: response.existingTradesCount,
                });
            }
            // if (response.skippedRowsCount) {
            //     importedResultMessage += i18n.t(`pages.trades.imported.skipped`, {
            //         skippedRowsCount: response.skippedRowsCount,
            //     });
            // }

            if (response.notFoundCurrencies.length) {
                this.importResultWarnings.push({
                    message: i18n.t(`pages.trades.notFoundCurrencies`, {
                        currencies: response.notFoundCurrencies.join(', '),
                    }),
                });
            }

            if (response.notFoundSymbols.length) {
                this.importResultWarnings.push({
                    message: i18n.t(`pages.trades.notFoundSymbols`, {
                        symbols: response.notFoundSymbols.join(', '),
                    }),
                    link: 'https://monery.zendesk.com/hc/cs/articles/29089328058001-Co-kdy%C5%BE-jsou-v-importu-nerozpoznan%C3%A9-symboly',
                });
            }

            if (response.invalidRowDates) {
                this.importResultWarnings.push({
                    message: i18n.t(`pages.trades.invalidRowDates`, {
                        invalidRowDates: response.invalidRowDates,
                    }),
                });
            }

            runInAction(() => {
                this.importedRowsCount = response.importedRowsCount;
                this.importResultMessage = importedResultMessage;

                if (response.importedRowsCount !== 0) {
                    if (this.recalculateAfterImport) {
                        this.rootStore.portfolioStore.selectedPortfolio.setIsRecalculating(true);
                    } else {
                        this.rootStore.portfolioStore.fetchPortfolioList();
                    }

                    this.tradeStore.fetchTradeList();
                }
            });
        } catch (e) {
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
            // eslint-disable-next-line no-console
            console.error(e);
        } finally {
            this.stopLoading();
        }
    }

    clearImportResultMessage() {
        this.importResultMessage = null;
    }

    clearImportResultWarnings() {
        this.importResultWarnings = [];
    }

    resetMessages() {
        this.importResultMessage = null;
        this.importResultWarnings = [];
    }
}
