import { makeAutoObservable, runInAction } from 'mobx';
import { ErrorStatusEnum } from '@app/common';
import type { RootStore } from './root-store';
import { PortfolioDialogStore } from './portfolio-dialog-store';
import { routes } from '../../config/routes';
import { PortfolioMobxDto } from '../mobx/dtos/portfolio/portfolio-mobx-dto';
import { PortfolioOverviewMobxDto } from '../mobx/dtos/portfolio/portfolio-overview-mobx-dto';
import { SharePortfolioDialogStore } from './share-portfolio-dialog-store';

export type PortfolioStoreHydration = {
    sharedPortfolio: PortfolioMobxDto;
};

/**
 * Slouží pro:
 * - zobrazení portfolií v dropdownu
 * - zobrazení portfolií na stránce moje portfolia
 * - práci s vybraným portfoliem
 */
export class PortfolioStore {
    rootStore: RootStore;

    portfolioDialogStore: PortfolioDialogStore;

    portfolios: PortfolioMobxDto[] = [];

    isRecalculatingTimeout: NodeJS.Timeout | null = null;

    loading = false;

    overview: PortfolioOverviewMobxDto | null = null;

    sharedPortfolio: PortfolioMobxDto | null = null;

    sharePortfolioDialogStore: SharePortfolioDialogStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.portfolioDialogStore = new PortfolioDialogStore(this);
        this.sharePortfolioDialogStore = new SharePortfolioDialogStore(this);

        makeAutoObservable(this, {
            rootStore: false,
            portfolioDialogStore: false,
            sharePortfolioDialogStore: false,
        });
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    hydrate(): PortfolioStoreHydration {
        const sharedPortfolio = JSON.parse(JSON.stringify(this.sharedPortfolio));
        return { sharedPortfolio };
    }

    rehydrate(data: PortfolioStoreHydration): void {
        if (data.sharedPortfolio) {
            this.sharedPortfolio = PortfolioMobxDto.create(data.sharedPortfolio);
        }
    }

    async fetchSharedPortfolio(shareSlug: string): Promise<void> {
        this.startLoading();
        try {
            const portfolio = await this.rootStore.apiClient.portfolioController.sharedPortfolioControllerShared({
                shareSlug,
            });

            if (portfolio) {
                runInAction(() => {
                    this.sharedPortfolio = PortfolioMobxDto.create(portfolio);
                });
            }
        } catch (e) {
            this.stopLoading();
            throw e;
        } finally {
            this.stopLoading();
        }
    }

    async fetchPortfolioList(): Promise<boolean> {
        try {
            this.startLoading();
            const fetchedPortfolios = await this.rootStore.apiClient.portfolioController.portfolioControllerGetList();
            runInAction(() => {
                if (fetchedPortfolios !== null) {
                    this.portfolios = PortfolioMobxDto.createFromArray(fetchedPortfolios);
                }
                this.stopLoading();
            });
            return true;
        } catch (e) {
            window.location.href = `${routes.web.index}?status=${ErrorStatusEnum.PortfoliosLoadFailed}`;
            return false;
        }
    }

    async fetchPortfolioOverview(): Promise<void> {
        try {
            this.startLoading();
            const fetchedOverview = await this.rootStore.apiClient.portfolioController.portfolioControllerGetOverview();
            if (fetchedOverview) {
                runInAction(() => {
                    this.overview = PortfolioOverviewMobxDto.create(fetchedOverview);
                });
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }
    }

    async selectPortfolio(portfolioId: string | null, noRedirect?: boolean): Promise<void> {
        if (!portfolioId) {
            return;
        }

        this.rootStore.tradeStore.tradeImportDialogStore.resetMessages();
        try {
            this.startLoading();
            await this.rootStore.apiClient.portfolioController.portfolioControllerSetSelected({ id: portfolioId });

            if (!noRedirect) {
                window.location.reload();
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }

        // TODO: root-store-provider issue
        // portfolio.setSelected(true);
        // this.portfolios.forEach((p) => {
        //     if (p.id !== portfolio.id) {
        //         p.setSelected(false);
        //     }
        // });

        // await this.rootStore.statisticStore.fetchIndexData();
        // await Router.replace(Router.asPath);
    }

    get selectedPortfolio(): PortfolioMobxDto {
        if (this.sharedPortfolio?.id) {
            return this.sharedPortfolio;
        }

        const portfolio = this.portfolios.find((p) => p.selected);
        if (portfolio) {
            return portfolio;
        }
        if (!portfolio && this.portfolios.length > 0) {
            return this.portfolios[0];
        }
        throw new Error('No available portfolios');
    }

    // get hasToUploadTrades(): boolean {
    //     const hasPortfolioValue =
    //         this.selectedPortfolio.invested > 0 ||
    //         this.selectedPortfolio.realizedInvested > 0 ||
    //         this.selectedPortfolio.portfolioValue > 0 ||
    //         this.selectedPortfolio.realizedPortfolioValue > 0;
    //
    //     return !hasPortfolioValue || this.selectedPortfolio.isRecalculating;
    // }

    get hasPortfolio(): boolean {
        return this.portfolios.length > 0;
    }

    waitForRecalculatingDone() {
        if (this.isRecalculatingTimeout !== null) {
            return;
        }

        this.isRecalculatingTimeout = setTimeout(async () => {
            try {
                const isRecalculating =
                    await this.rootStore.apiClient.portfolioController.portfolioControllerIsRecalculating({
                        id: this.selectedPortfolio.id,
                    });

                runInAction(() => {
                    if (isRecalculating?.isRecalculating === true) {
                        this.isRecalculatingTimeout = null;
                        this.waitForRecalculatingDone();
                    } else {
                        this.isRecalculatingTimeout = null;

                        const portfolioValueCalculationInfoQuery = this.rootStore
                            .portfolioValueCalculationInfoDialogStore.opened
                            ? '?portfolioValueCalculationInfo=true'
                            : '';
                        window.location.replace(routes.app.index + portfolioValueCalculationInfoQuery);
                    }
                });
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e);
                this.rootStore.alertStore.setErrorMessageByStatus(e.message);
            }
        }, 10000);
    }

    clearSharedPortfolio() {
        this.sharedPortfolio = null;
    }

    async reorderPortfolios(sourceIndex: number, destinationIndex: number) {
        if (sourceIndex === destinationIndex) {
            return;
        }

        const reorderedPortfolios = Array.from(this.portfolios);
        const [movedItem] = reorderedPortfolios.splice(sourceIndex, 1);
        reorderedPortfolios.splice(destinationIndex, 0, movedItem);
        this.portfolios = reorderedPortfolios;

        try {
            await this.rootStore.apiClient.portfolioController.portfolioControllerReorderPortfolios({
                reorderPortfoliosDto: {
                    portfolioIds: reorderedPortfolios.map((p) => p.id),
                },
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        }
    }
}
