/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CurrencyEntity,
    CurrencyEntityFromJSON,
    CurrencyEntityFromJSONTyped,
    CurrencyEntityToJSON,
} from './CurrencyEntity';
import {
    PortfolioEntity,
    PortfolioEntityFromJSON,
    PortfolioEntityFromJSONTyped,
    PortfolioEntityToJSON,
} from './PortfolioEntity';
import {
    SymbolWithExchangeDto,
    SymbolWithExchangeDtoFromJSON,
    SymbolWithExchangeDtoFromJSONTyped,
    SymbolWithExchangeDtoToJSON,
} from './SymbolWithExchangeDto';
import {
    TradeTypeEnum,
    TradeTypeEnumFromJSON,
    TradeTypeEnumFromJSONTyped,
    TradeTypeEnumToJSON,
} from './TradeTypeEnum';

/**
 * 
 * @export
 * @interface TradeListWithSplitDto
 */
export interface TradeListWithSplitDto {
    /**
     * 
     * @type {TradeTypeEnum}
     * @memberof TradeListWithSplitDto
     */
    type: TradeTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof TradeListWithSplitDto
     */
    value: number;
    /**
     * 
     * @type {SymbolWithExchangeDto}
     * @memberof TradeListWithSplitDto
     */
    symbol: SymbolWithExchangeDto;
    /**
     * 
     * @type {Date}
     * @memberof TradeListWithSplitDto
     */
    date: Date;
    /**
     * 
     * @type {number}
     * @memberof TradeListWithSplitDto
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof TradeListWithSplitDto
     */
    unitPrice: number;
    /**
     * 
     * @type {number}
     * @memberof TradeListWithSplitDto
     */
    fees: number;
    /**
     * 
     * @type {string}
     * @memberof TradeListWithSplitDto
     */
    note?: string | null;
    /**
     * 
     * @type {CurrencyEntity}
     * @memberof TradeListWithSplitDto
     */
    currency: CurrencyEntity;
    /**
     * 
     * @type {string}
     * @memberof TradeListWithSplitDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TradeListWithSplitDto
     */
    referenceId?: string | null;
    /**
     * 
     * @type {PortfolioEntity}
     * @memberof TradeListWithSplitDto
     */
    portfolio: PortfolioEntity;
    /**
     * 
     * @type {number}
     * @memberof TradeListWithSplitDto
     */
    split: number;
}

export function TradeListWithSplitDtoFromJSON(json: any): TradeListWithSplitDto {
    return TradeListWithSplitDtoFromJSONTyped(json, false);
}

export function TradeListWithSplitDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TradeListWithSplitDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': TradeTypeEnumFromJSON(json['type']),
        'value': json['value'],
        'symbol': SymbolWithExchangeDtoFromJSON(json['symbol']),
        'date': (new Date(json['date'])),
        'amount': json['amount'],
        'unitPrice': json['unitPrice'],
        'fees': json['fees'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'currency': CurrencyEntityFromJSON(json['currency']),
        'id': json['id'],
        'referenceId': !exists(json, 'referenceId') ? undefined : json['referenceId'],
        'portfolio': PortfolioEntityFromJSON(json['portfolio']),
        'split': json['split'],
    };
}

export function TradeListWithSplitDtoToJSON(value?: TradeListWithSplitDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': TradeTypeEnumToJSON(value.type),
        'value': value.value,
        'symbol': SymbolWithExchangeDtoToJSON(value.symbol),
        'date': (value.date.toISOString()),
        'amount': value.amount,
        'unitPrice': value.unitPrice,
        'fees': value.fees,
        'note': value.note,
        'currency': CurrencyEntityToJSON(value.currency),
        'id': value.id,
        'referenceId': value.referenceId,
        'portfolio': PortfolioEntityToJSON(value.portfolio),
        'split': value.split,
    };
}

