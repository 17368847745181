import { makeAutoObservable, runInAction } from 'mobx';
import type { RootStore } from './root-store';
// eslint-disable-next-line import/no-cycle
import { SymbolMobxDto } from '../mobx/dtos/symbol/symbol-mobx-dto';
import type { SymbolsStore } from './symbols-store';
import { RecalculateSymbolMobxDto } from '../mobx/dtos/symbol/recalculate-symbol-mobx-dto';

export class RecalculateSymbolStore {
    rootStore: RootStore;

    symbolsStore: SymbolsStore;

    symbolSearchPhrase = '';

    symbols: SymbolMobxDto[] = [];

    loading = false;

    loadingSearch = false;

    createOrUpdateErrorMessage: string | null = null;

    private delayTimer: NodeJS.Timeout;

    private abortController: AbortController | null = null;

    constructor(symbolsStore: SymbolsStore) {
        this.rootStore = symbolsStore.rootStore;
        this.symbolsStore = symbolsStore;

        makeAutoObservable(this, {
            rootStore: false,
            symbolsStore: false,
        });
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    async recalculate(recalculateSymbol: RecalculateSymbolMobxDto): Promise<void> {
        runInAction(() => {
            this.createOrUpdateErrorMessage = null;
        });

        try {
            this.startLoading();
            await this.rootStore.apiClient.symbolController.symbolControllerRecalculateSymbolData({
                recalculateSymbolDto: recalculateSymbol,
            });
        } catch (e) {
            runInAction(() => {
                this.createOrUpdateErrorMessage = e.message;
            });
            // eslint-disable-next-line no-console
            console.error(e);
        } finally {
            this.stopLoading();
        }
    }

    setSymbolSearchPhrase(phrase: string): void {
        this.symbolSearchPhrase = phrase;
    }

    async triggerSearch(symbol?: SymbolMobxDto): Promise<void> {
        this.loadingSearch = true;
        clearTimeout(this.delayTimer);
        this.delayTimer = setTimeout(async () => {
            await this.fetchSymbols(symbol);
            runInAction(() => {
                this.loadingSearch = false;
            });
        }, 500);
    }

    async fetchSymbols(symbol: SymbolMobxDto | null = null): Promise<void> {
        this.startLoading();
        await this.rootStore.symbolsStore.fetchSymbols();
        try {
            if (this.abortController) {
                this.abortController.abort();
            }

            this.abortController = new AbortController();
            const { signal } = this.abortController;

            const symbols = await this.rootStore.apiClient.symbolController.symbolControllerGetList(
                {
                    phrase: this.symbolSearchPhrase,
                    limit: 100,
                },
                {
                    signal,
                },
            );
            runInAction(() => {
                const newSymbols =
                    symbols?.map((s) => ({
                        ...s,
                        marketCapitalizationBn: null,
                    })) || [];

                if (symbol && !newSymbols.find((s) => s.id === symbol.id)) {
                    this.symbols = [symbol, ...newSymbols];
                } else {
                    this.symbols = newSymbols;
                }
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }
    }
}
