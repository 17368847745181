/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SymbolHistoryCloseDto
 */
export interface SymbolHistoryCloseDto {
    /**
     * 
     * @type {number}
     * @memberof SymbolHistoryCloseDto
     */
    close?: number;
}

export function SymbolHistoryCloseDtoFromJSON(json: any): SymbolHistoryCloseDto {
    return SymbolHistoryCloseDtoFromJSONTyped(json, false);
}

export function SymbolHistoryCloseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SymbolHistoryCloseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'close': !exists(json, 'close') ? undefined : json['close'],
    };
}

export function SymbolHistoryCloseDtoToJSON(value?: SymbolHistoryCloseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'close': value.close,
    };
}

