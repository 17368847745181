/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LeaderboardPositionDetailDto
 */
export interface LeaderboardPositionDetailDto {
    /**
     * 
     * @type {number}
     * @memberof LeaderboardPositionDetailDto
     */
    position: number;
    /**
     * 
     * @type {number}
     * @memberof LeaderboardPositionDetailDto
     */
    value: number;
    /**
     * 
     * @type {number}
     * @memberof LeaderboardPositionDetailDto
     */
    percentile: number;
}

export function LeaderboardPositionDetailDtoFromJSON(json: any): LeaderboardPositionDetailDto {
    return LeaderboardPositionDetailDtoFromJSONTyped(json, false);
}

export function LeaderboardPositionDetailDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaderboardPositionDetailDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'position': json['position'],
        'value': json['value'],
        'percentile': json['percentile'],
    };
}

export function LeaderboardPositionDetailDtoToJSON(value?: LeaderboardPositionDetailDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'position': value.position,
        'value': value.value,
        'percentile': value.percentile,
    };
}

